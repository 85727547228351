import React from 'react';
import styles from './vacancy.module.scss';

export default function VacancyItem({ vacancy }) {
  const {
    title,
    guarantees,
    expectations,
    requirements,
    terms,
    bonuses,
    image,
  } = vacancy;

  return (
    <div className={styles.vacancyItem}>
      <h2 className={styles.itemTitle}>{title}</h2>
      <div className={styles.item}>
        <div className={styles.itemContainer}>
          <div className={styles.itemInfo}>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>
                Со своей стороны гарантируем:
              </h2>
              <div className={styles.itemInfoList}>{guarantees}</div>
            </div>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>От Вас ожидаем:</h2>
              <p className={styles.itemInfoList}>{expectations}</p>
            </div>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>Требования:</h2>
              <p className={styles.itemInfoList}>{requirements}</p>
            </div>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>Условия:</h2>
              <p className={styles.itemInfoList}>{terms}</p>
            </div>
            <div className={styles.itemInfoBox}>
              <h2 className={styles.itemInfoTitle}>Бонусы:</h2>
              <p className={styles.itemInfoList}>{bonuses}</p>
            </div>
            <div className={styles.itemInfoContacts}>
              <h2
                className={
                  (styles.itemInfoTitle, styles.itemInfoTitleMainColor)
                }
              >
                Отдел подбора персонала:
              </h2>
              <a className={styles.itemTel} href="tel:+79200582458">
                +7 (920) 058 24 58
              </a>
              <a className={styles.itemEmail} href="mailto:info@grand.gold">
                info@grand.gold
              </a>
            </div>
          </div>
          <img className={styles.itemImage} src={image} alt={title} />
        </div>
      </div>
    </div>
  );
}
