const renderBonusMessage = (isManelyak, bonusSumm) => {
  if (isManelyak) {
    return <p>Получите {bonusSumm} руб. на бонусную карту Grand Gold</p>;
  } else {
    return (
      <p>
        Получите дополнительную скидку до 5%
        <br />
        или {bonusSumm} руб. на бонусную карту Grand Gold
      </p>
    );
  }
};

export default renderBonusMessage;
