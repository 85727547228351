import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styles from '../../Forms.module.scss';

import validatePhone from 'shared/lib/validatePhone';
import {
  onPhoneInput,
  onPhoneKeyDown,
  onPhonePaste,
} from 'shared/lib/validatePhoneNumber';

import { useGetLoginCode } from 'api/Auth/Login/useGetLoginCode';
import ReCAPTCHA from 'react-google-recaptcha';
import { MainButton } from 'shared/ui/MainButton';
import { SecondaryButton } from 'shared/ui/SecondaryButton';
import { PrivacyPolicy } from 'shared/ui/PrivacyPolicy';

function BuyerEnter() {
  const { loginError, loginLoading, getLoginCode } = useGetLoginCode();
  const recaptchaRef = useRef();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = async (data) => {
    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      await getLoginCode(data, 'call', '/sign-in');
    }
  };

  return (
    <form className={styles.inputsContainer} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.inputContainer}>
        <label className={styles.inputLabel}>
          <input
            className={styles.input}
            type="tel"
            onPaste={onPhonePaste}
            onInput={onPhoneInput}
            onKeyDown={onPhoneKeyDown}
            {...register('phone_number', {
              required: 'Поле обязательно к заполнению',
              validate: (value) => validatePhone(value.trim()),
            })}
            placeholder="+7 (900) 123 45 67"
          />
        </label>
        {loginError !== null ? (
          <div className={styles.error}>
            <p>{loginError}</p>
          </div>
        ) : (
          <div className={styles.error}>
            {errors?.phone_number && (
              <p>{errors?.phone_number?.message || 'Кажется, здесь ошибка'}</p>
            )}
          </div>
        )}
      </div>
      <div className={styles.buttonsContainer}>
        <MainButton
          type="submit"
          text="Выполнить вход"
          loading={loginLoading}
        />
        <Link to="/register">
          <SecondaryButton text="Зарегистрироваться" />
        </Link>
      </div>
      <PrivacyPolicy />
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_CAPTHA_KEY}
        size="invisible"
      />
    </form>
  );
}

export { BuyerEnter };
