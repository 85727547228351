import { Link } from 'react-router-dom';
import styles from './PrivacyPolicy.module.scss';

function PrivacyPolicy() {
  return (
    <div className={styles.privacyPolictContainer}>
      Нажимая на кнопку, Вы принимаете{' '}
      <Link to="/info">условия пользовательского соглашения</Link> нашего сайта
      и{' '}
      <a href="https://policies.google.com/privacy?hl=ru" target="noopener">
        Конфиденциальность
      </a>{' '}
      и{' '}
      <a href="https://policies.google.com/terms?hl=ru" target="noopener">
        Условия использования
      </a>{' '}
      reCAPTCHA
    </div>
  );
}

export { PrivacyPolicy };
