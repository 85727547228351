import { create } from 'zustand';
import $api from '../app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';

let items = (set) => ({
  items: [],
  itemsCount: 0,
  totalPrice: 0,
  isManelyak: false,
  loading: false,
  error: null,

  getCart: async () => {
    set({ loading: true });

    try {
      const response = await $api.get(urls.getCart);

      const isManelyak = response.data[0]?.cart_items?.some(
        (item) => item.is_manelyak ?? false,
      );

      set({
        items: [...response.data[0].cart_items],
        itemsCount: response.data[0].cart_items.length,
        totalPrice: Number(response.data[0].total_price),
        isManelyak: isManelyak,
      });
    } catch (error) {
      set({
        items: [],
        itemsCount: 0,
        totalPrice: 0,
        isManelyak: false,
      });
    } finally {
      set({ loading: false });
    }
  },
  addToCart: async (id) => {
    set({ addLoading: true });

    try {
      await $api.post(urls.addToCart, {
        trade_offer: id,
      });
    } catch (error) {
      console.log('ошибка при добавлении товара');
    } finally {
      set({ addLoading: false });
    }
  },
  removeFromCart: async (id) => {
    set({ loading: true });

    try {
      const response = await $api.post(urls.removeFromCart, {
        trade_offer: id,
      });

      const isManelyak = response.data?.cart_items?.some(
        (item) => item.is_manelyak ?? false,
      );

      set({
        items: [...response.data.cart_items],
        itemsCount: response.data.cart_items_count,
        totalPrice: Number(response.data.total_price),
        isManelyak: isManelyak,
      });
    } catch (error) {
      console.log('ошибка при удалении товара');
    } finally {
      set({ loading: false });
    }
  },
});

export const useCart = create(items);
