import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './Item.module.scss';

import { useFetchItem } from 'api/Catalog/useFetchItem';
import { useCart } from 'store/cart';

import { useBuyOneClickModal } from 'entities/Modal/ui/components/BuyOneClickModal/lib/store';
import sendYAMetrik from 'shared/lib/sendYAMetrik';

import { ItemMainSkelleton } from 'shared/ui/Skelletons/ItemMain';
import ItemPhotoSlider from '../components/ItemPhotoSlider/ItemPhotoSlider';
import ItemDiscription from '../components/ItemDiscription/ItemDiscription';
import MainInfo from '../components/ItemMainInfo/ItemMainInfo';
import ItemButtons from '../components/ItemButtons/ItemButtons';
import ItemFAQ from '../components/ItemFAQ/ItemFAQ';
import Offers from '../components/Offers/Offers';
import { CarouselItems } from 'widgets/CarouselItems';
import { VideoPlayer } from 'entities/VideoPlayer';

function Item() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { item, isInCart, loading, error, fetchItem } = useFetchItem();
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [isItemInCart, setIsItemInCart] = useState(() => isInCart || false);
  const inCart = isItemInCart || isInCart;
  const addToCart = useCart((state) => state.addToCart);
  const [isVideoPlayerOpen, setIsVideoPlayerOpen] = useState(false);

  const onAddToCart = async () => {
    setAddToCartLoading(true);
    await addToCart(item.id);
    setAddToCartLoading(false);
    setIsItemInCart(true);
    // Отправляю данные в Яндекс.Метрику
    sendYAMetrik('reachGoal', 'add');
  };

  // Купить в 1 клик
  const { setActiveModal, setItems } = useBuyOneClickModal((state) => ({
    setActiveModal: state.setActiveModal,
    setItems: state.setItems,
  }));

  const onBuyOneClickModal = () => {
    setItems(item.id.toString());
    setActiveModal(true);
    // Отправляю данные в Яндекс.Метрику
    sendYAMetrik('reachGoal', '1click');
  };

  useEffect(() => {
    fetchItem(id);
  }, [id]);

  if (error) {
    navigate('/404');
  }

  if (loading || !item.goods_images) {
    return <ItemMainSkelleton />;
  }

  return (
    <div className={styles.page}>
      <div className={styles.itemContainer}>
        <ItemPhotoSlider
          item={item}
          id={item.id}
          title={item.title}
          quantity={item.quantity}
          is_active={item.is_active}
          discount={item.discount}
          final_discount={item.final_discount}
          images={item?.goods_images}
          isInFavorite={item.is_in_favorites}
          video={item?.video[0]?.video}
          setIsVideoPlayerOpen={setIsVideoPlayerOpen}
        />
        <div className={styles.itemInfo}>
          <h1 className={styles.itemTitle}>{item.title}</h1>
          <MainInfo
            title={item.title}
            price={item.price}
            quantity={item.quantity}
            is_active={item.is_active}
            red_price={item.red_price}
            discount_price={item.discount_price}
            discount={item.discount}
            is_manelyak={item.is_manelyak}
          />
          <Offers offers={item?.other_offers} />
          <ItemButtons
            id={item.id}
            quantity={item.quantity}
            is_active={item.is_active}
            inCart={inCart}
            onAddToCart={onAddToCart}
            loading={addToCartLoading}
            onBuyOneClickModal={onBuyOneClickModal}
          />
          <ItemDiscription item={item} />
          <ItemFAQ />
        </div>
      </div>
      {/* Предложки товаров */}
      <CarouselItems title="Похожие товары" items={item?.similar_goods} />
      {/* Дальше модалки */}
      {item?.video.length ? (
        <VideoPlayer
          isOpen={isVideoPlayerOpen}
          setIsOpen={setIsVideoPlayerOpen}
          url={item.video[0].video}
        />
      ) : null}
    </div>
  );
}

export { Item };
