import React, { useState, useEffect, useRef } from 'react';
import styles from './MobileNavbar.module.scss';
import { NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import { useNavbar } from '../../Burger/lib/store';
import { useWindowSize } from 'shared/lib/windowSizeChecker';
import { useOutsideClick } from 'shared/lib/useOutsideClick';

function MobileNavbar() {
  const { navbarState, setNavbarState } = useNavbar((state) => ({
    navbarState: state.navbarState,
    setNavbarState: state.setNavbarState,
  }));

  const animate = {
    hide: {
      x: 200,
      opacity: 0,
    },
    show: {
      x: 0,
      opacity: 1,
    },
    exit: {
      x: 200,
    },
  };

  // Закрываю при нажатии вне блока
  const navbarRef = useRef();
  useOutsideClick(navbarRef, () => setNavbarState(false));

  // Слежу за изменением экрана
  const [mobile, setMobile] = useState(false);
  const windowSize = useWindowSize();

  function handleCloseNavbar() {
    setNavbarState(false);
  }

  // Изменяем расположение поиска
  useEffect(() => {
    if (windowSize <= 768) {
      setMobile(true);
    } else setMobile(false);
  }, [windowSize]);

  return (
    <AnimatePresence>
      {navbarState && (
        <motion.div
          className={styles.mobileNavbar}
          ref={navbarRef}
          variants={animate}
          initial="hide"
          animate="show"
          exit="exit"
          transition={{ type: 'just' }}
        >
          <nav className={styles.navbarList}>
            {!mobile ? (
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? styles.linkButtonActive : styles.linkButton
                }
                onClick={handleCloseNavbar}
              >
                Главная
              </NavLink>
            ) : null}
            <NavLink
              to="catalog"
              className={({ isActive }) =>
                isActive ? styles.linkButtonActive : styles.linkButton
              }
              onClick={handleCloseNavbar}
            >
              Каталог
            </NavLink>

            <NavLink
              to="yuvelirnyie-rabotyi"
              className={({ isActive }) =>
                isActive ? styles.linkButtonActive : styles.linkButton
              }
              onClick={handleCloseNavbar}
            >
              Ювелирные работы
            </NavLink>
            <NavLink
              to="otzyvy"
              className={({ isActive }) =>
                isActive ? styles.linkButtonActive : styles.linkButton
              }
              onClick={handleCloseNavbar}
            >
              Отзывы
            </NavLink>
            <NavLink
              to="about"
              className={({ isActive }) =>
                isActive ? styles.linkButtonActive : styles.linkButton
              }
              onClick={handleCloseNavbar}
            >
              Контакты
            </NavLink>
            <NavLink
              to="https://lombard.grand.gold/"
              target="_blank"
              className={({ isActive }) =>
                isActive ? styles.linkButtonActive : styles.linkButton
              }
              onClick={handleCloseNavbar}
            >
              Ломбард
            </NavLink>
            <NavLink
              to="/vacancy"
              className={({ isActive }) =>
                isActive ? styles.linkButtonActive : styles.linkButton
              }
            >
              Вакансии
            </NavLink>
          </nav>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export { MobileNavbar };
