import styles from './CartItems.module.scss';

import wordsDeclention from 'shared/lib/wordsDeclention';
import { CardHorizontal } from 'entities/CardHorizontal';

const words = ['изделие', 'изделия', 'изделий'];

function CartItems({ items, loading, itemsCount, removeFromCart }) {
  if (loading) {
    return <div className={styles.cartContainerSkelleton}></div>;
  }

  return (
    <div className={styles.cartsContainer}>
      <div className={styles.head}>
        <h2 className={styles.title}>{`${itemsCount} ${wordsDeclention(
          itemsCount,
          words,
        )}`}</h2>
      </div>
      {items.map((item) => (
        <CardHorizontal
          key={item.trade_offer}
          id={item.trade_offer}
          title={item.title}
          price={item.trade_offer_price}
          red_price={item.red_price}
          discount_price={item.price_without_discount}
          discount={item.discount}
          image={item.image}
          is_active={item.is_active}
          quantity={item.quantity}
          slug_url={item.slug_url}
          isInFavorite={item.is_in_favorites}
          onRemoveFromCart={removeFromCart}
          is_manelyak={item.is_manelyak}
        />
      ))}
    </div>
  );
}

export { CartItems };
