export const API_URL =
  process.env.REACT_APP_API_URL || 'https://devgrandgold.ru';

export const urls = {
  // Товары
  // Получание всех товаров
  getItems: `${API_URL}/api/main/v2/goods/`,
  // Получание конкретного товара
  getItem: `${API_URL}/api/main/v2/product/`,
  // Получание всех товаров для сео-каталога
  getSeoItems: `${API_URL}/api/main/v2/catalog/`,

  // Авторизация
  // Получение кода при входе
  enterPhone: `${API_URL}/api/sms-auth/sign-in/`,
  // Подтверждение кода при регистрации
  regPhone: `${API_URL}/api/sms-auth/sign-up/`,
  // Обновление токенов
  checkAuth: `${API_URL}/api/profile/refresh`,

  // Пользователь
  // Регистрация
  register: `${API_URL}/api/sms-auth/register/`,
  // Вход
  login: `${API_URL}/api/sms-auth/auth/`,
  // Получение пользователя
  getUser: `${API_URL}/api/profile/`,
  // Выход
  logout: `${API_URL}/api/profile/logout/`,

  // Корзина
  // Добавление товара
  addToCart: `${API_URL}/api/main/v2/cart/add/`,
  // Удаление товара
  removeFromCart: `${API_URL}/api/main/v2/cart/delete/`,
  // Получение корзины
  getCart: `${API_URL}/api/main/v2/cart/get/`,

  // Заказы
  // Получение заказов
  getOrders: `${API_URL}/api/main/v2/orders/`,
  // Отправление заказа
  postOrder: `${API_URL}/api/main/v2/order/`,
  // Подсчет стоимости доставки и страховки
  calcDeliveryCost: `${API_URL}/api/main/v2/cart/calc/`,
  // Оплата Тинькофф
  payOrderTinkoff: `${API_URL}/api/main/v2/pay-order/`,

  // Избранные товары
  // Получение избранных товаров
  getFavorites: `${API_URL}/api/main/v2/favorites/`,
  // Добавление
  addToFavorites: `${API_URL}/api/main/v2/favorites/`,
  // Удаление
  removeFromFavorites: `${API_URL}/api/main/v2/favorites/delete/`,

  // Блоги
  // Вопрос-ответ
  getFaqTopics: `${API_URL}/api/main/faq/`,

  // Модальные окна
  // Купить в 1 клик
  buy1Click: `${API_URL}/api/main/v2/one-click/`,
  // Заявка на изготовление
  jewelryMaking: `${API_URL}/api/main/v2/jewelry-making/`,
  // Заявка на ремонт
  jewelryRepair: `${API_URL}/api/main/v2/jewelry-repair/`,

  // Баннеры
  // Основные акции и отдельные страницы
  getPromoBanners: `${API_URL}/api/main/promo-banners/`,
  // На странице Юв. работ
  getJewelryBanners: `${API_URL}/api/main/jewelry-works-banners/`,

  // СДЭК
  getCdekWidget: `${API_URL}/api/cdek/widget/scripts/service3/`,

  // Вакансии
  getVacancies: `${API_URL}/api/main/vacancies/`,
};
