import React from 'react';
import styles from './Buyer.module.scss';

import { BuyerEnter } from 'entities/Auth/BuyerEnter';

function Buyer() {
  return (
    <div className={styles.buyer}>
      <div className={styles.info}>
        <h2 className={styles.titleInfo}>Войдите или зарегистрируйтесь</h2>
        <p className={styles.textInfo}>
          Выполните вход в личный кабинет, чтобы совершать покупки и получать
          бонусы на Вашу карту Grand Gold. Бонусами можно оплачивать до 5% от
          стоимости заказа
        </p>
      </div>
      <BuyerEnter />
    </div>
  );
}

export { Buyer };
