import { useState } from 'react';

import $api from 'app/axios/axiosConfig';
import { urls } from 'api/Config/usrlsConfig';

function useVacancies() {
  const [vacancies, setVacancies] = useState([]);
  const [vacanciesLoading, setVacanciesLoading] = useState(false);
  const [vacanciesError, setVacanciesError] = useState(false);

  async function getVacancies() {
    setVacanciesLoading(true);

    try {
      const response = await $api.get(urls.getVacancies);

      setVacancies(response.data);
    } catch (error) {
      console.log(error);
      setVacanciesError(error.message);
    } finally {
      setVacanciesLoading(false);
    }
  }

  return {
    vacanciesLoading,
    vacanciesError,
    vacancies,
    getVacancies,
  };
}

export { useVacancies };
