import StoresRecievType from '../components/StoresRecievType/StoresRecievType';
import PostRecievType from '../components/PostRecievType/PostRecievType';
import CDEKWidget from '../components/CDEKWidget/CDEKWidget';

const defaultRecievMethods = [
  {
    title: 'Самовывоз в Нижнем Новгороде',
    text: 'Забрать заказ из нашего магазина',
    key: 'pickup',
    children: <StoresRecievType />,
  },
  {
    title: 'Почта России',
    text: 'Доставка в ближайшее отделение Почты России',
    key: 'russian_post',
    children: <PostRecievType />,
  },
  {
    title: 'СДЭК',
    text: 'Доставка в ближайший пунк выдачи СДЭК или до Вашей двери',
    key: 'cdek',
    children: <CDEKWidget />,
  },
];

const manelyakRecievMethods = [
  {
    title: 'Самовывоз в Нижнем Новгороде',
    text: 'Забрать заказ из нашего магазина',
    key: 'pickup',
    children: <StoresRecievType />,
  },
];

export { defaultRecievMethods, manelyakRecievMethods };
