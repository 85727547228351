import React, { useEffect, useState } from 'react';
import styles from './Total.module.scss';
import { useShallow } from 'zustand/react/shallow';

import sendMetrik from 'shared/lib/sendYAMetrik';
import { useAddOrder } from 'api/Order/useAddOrder';
import { useOrder } from 'store/order';
import { useUser } from 'store/user';
import { useBuyOneClickModal } from 'entities/Modal/ui/components/BuyOneClickModal/lib/store';

import { MainButton } from 'shared/ui/MainButton';
import { SecondaryButton } from 'shared/ui/SecondaryButton';
import TotalError from '../components/TotalError/TotalError';
import TotalMainPrice from '../components/TotalMainPrice/TotalMainPrice';
import CalculateTotal from '../components/CalculateTotal/CalculateTotal';
import EmptyTotal from '../components/EmptyTotal/EmptyTotal';
import sendYAMetrik from 'shared/lib/sendYAMetrik';
import roundPrice from 'shared/lib/roundPrice';

function Total({ items, loading }) {
  const isAuth = useUser((state) => state.isAuth);
  const [isValid, setValid] = useState(false);
  const [summTotal, setSummTotal] = useState(null);
  // Стоимость товаров без учета скидок
  const summOldPrises = items.reduce((total, item) => {
    // Если товар неактивен, не будем считать его стоимость
    if (item.is_active && item.quantity) {
      return (total = total + Number(item.price_without_discount));
    } else return total;
  }, 0);
  // Сумма сэкономленных средств
  const summEconomy = summOldPrises - summTotal;
  // Достаем заказ
  const { addOrderLoading, addOrder } = useAddOrder();
  const {
    address,
    delivery_type,
    payment_type,
    delivery_cost,
    express_delivery,
    location_code,
    pvz,
  } = useOrder(
    useShallow((state) => ({
      address: state.address,
      delivery_type: state.delivery_type,
      payment_type: state.payment_type,
      delivery_cost: state.delivery_cost,
      express_delivery: state.express_delivery,
      location_code: state.location_code,
      pvz: state.pvz,
    })),
  );

  // Модалка "Купить в 1 клик"
  const { setActiveOneClick, setOneClickItems } = useBuyOneClickModal(
    (state) => ({
      setActiveOneClick: state.setActiveModal,
      setOneClickItems: state.setItems,
    }),
  );

  const postOrder = () => {
    addOrder(
      address,
      delivery_type,
      payment_type,
      express_delivery,
      location_code,
      pvz,
    );

    sendMetrik('reachGoal', 'order');
  };

  const onOneClickModal = () => {
    // Подготавливаю данные о товарах для "Купить в 1 клик"
    const tradeOffers = items.map((item) => item.trade_offer);

    setActiveOneClick(true);
    setOneClickItems(tradeOffers.join(', '));

    sendYAMetrik('reachGoal', '1clickcart');
  };

  // Считаю ИТОГО
  useEffect(() => {
    const itago = items.reduce((acc, item) => {
      return (acc = acc + roundPrice(item.trade_offer_price));
    }, 0);

    setSummTotal(itago);
  }, [payment_type, items]);

  // Валидация корзины
  useEffect(() => {
    if (payment_type && delivery_type && address) {
      setValid(true);
    } else setValid(false);
  }, [delivery_type, address, payment_type]);

  if (loading) {
    return <div className={styles.totalSkelleton}></div>;
  }

  // Отображаем этот блок, если товары были проданы
  if (!summOldPrises) {
    return (
      <div className={styles.totalContainer}>
        <EmptyTotal />
      </div>
    );
  }

  return (
    <div className={styles.totalContainer}>
      <TotalMainPrice summTotal={summTotal} delivery_cost={delivery_cost} />
      <CalculateTotal
        itemsLength={items.length}
        summEconomy={summEconomy}
        delivery_cost={delivery_cost}
        summOldPrises={summOldPrises}
      />
      <div className={styles.btnsContainer}>
        <MainButton
          text="Оформить заказ"
          onClick={postOrder}
          disabled={!isAuth || !isValid}
          loading={addOrderLoading}
        />
        <SecondaryButton text="Купить в 1 клик" onClick={onOneClickModal} />
        {isValid && (
          <p className={styles.attention}>
            После нажатия на кнопку "Оформить заказ", с Вами свяжется наш
            сотрудник для уточнения данных и подтверждения заказа
          </p>
        )}
      </div>
      {!isAuth && (
        <p className={styles.attention}>
          Войдите в личный кабинет, либо воспользуйтесь кнопкой "Купить в 1
          клик"
        </p>
      )}
      {/* Вывод предупреждений */}
      {isAuth && !isValid && (
        <TotalError payment_type={payment_type} address={address} />
      )}
    </div>
  );
}

export { Total };
