import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './CartPage.module.scss';

import { useUser } from 'store/user';
import { useCart } from 'store/cart';

import { CartItems } from 'widgets/Cart/ui/CartItems';
import { Receiv } from 'widgets/Cart/ui/Receiv';
import { Buyer } from 'widgets/Cart/ui/Buyer';
import { Total } from 'widgets/Cart/ui/Total';
import { Payment } from 'widgets/Cart/ui/Payment';

import { CartSkelleton } from 'shared/ui/Skelletons/Cart';
import { MainButton } from 'shared/ui/MainButton';
import { BsBagX } from 'react-icons/bs';
import ManelyakMessage from 'shared/ui/ManelyakMessage/ManelyakMessage';

function CartPage() {
  const isAuth = useUser((state) => state.isAuth);
  const [activeMalenyakMessage, setActiveManeluakMessage] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const {
    items,
    itemsCount,
    totalPrice,
    isManelyak,
    loading,
    getCart,
    removeFromCart,
  } = useCart((state) => ({
    items: state.items,
    itemsCount: state.itemsCount,
    totalPrice: state.totalPrice,
    isManelyak: state.isManelyak,
    loading: state.loading,
    getCart: state.getCart,
    removeFromCart: state.removeFromCart,
  }));

  const initializePage = async () => {
    await getCart();
    setPageLoading(false);
  };

  useEffect(() => {
    setActiveManeluakMessage(isManelyak && isAuth);
  }, [isManelyak, isAuth]);

  useEffect(() => {
    initializePage();
  }, []);

  if (pageLoading) {
    return (
      <div className={styles.cartPage}>
        <h1 className={styles.title}>Корзина товаров</h1>
        <CartSkelleton />
      </div>
    );
  }

  if (!pageLoading && !itemsCount) {
    return (
      <div className={styles.cartPage}>
        <h1 className={styles.title}>Корзина товаров</h1>
        <div className={styles.emptyCartContainer}>
          <BsBagX size={100} />
          <h3>Здесь пока пусто...</h3>
          <Link to="/catalog">
            <MainButton text="Перейти в Каталог" />
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.cartPage}>
      <h1 className={styles.title}>Корзина товаров</h1>
      <div className={styles.filledCart}>
        <div className={styles.mainContainer}>
          <div className={styles.mainModulesContainer}>
            <CartItems
              items={items}
              loading={loading}
              itemsCount={itemsCount}
              removeFromCart={removeFromCart}
            />
            {isAuth ? (
              <>
                <Receiv isManelyak={isManelyak} />
                <Payment isManelyak={isManelyak} />
              </>
            ) : (
              <Buyer />
            )}
          </div>
          <Total items={items} loading={loading} totalPrice={totalPrice} />
        </div>
      </div>
      <ManelyakMessage
        active={activeMalenyakMessage}
        onClose={() => setActiveManeluakMessage(false)}
      />
    </div>
  );
}

export { CartPage };
