import React, { useEffect, useState } from 'react';
import { NotificationModal } from '../../ModalLayouts/NotificationModal';
import { MainButton } from 'shared/ui/MainButton';
import styles from './CookiesModal.module.scss';

function CookiesModal() {
  const [active, setActive] = useState(false);

  const handleClose = () => {
    localStorage.setItem('accept-cookie', 'true');
    setActive(false);
  };

  useEffect(() => {
    let timer;

    if (!localStorage.getItem('accept-cookie')) {
      timer = setTimeout(() => {
        setActive(true);
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, []);

  return (
    <NotificationModal active={active}>
      <div className={styles.container}>
        <h3 className={styles.message}>
          Мы используем файлы cookie, чтобы пользоваться сайтом было удобно
        </h3>
        <MainButton text="Хорошо" onClick={handleClose} />
      </div>
    </NotificationModal>
  );
}

export { CookiesModal };
