import React, { useEffect } from 'react';
import styles from './JWorksPage.module.scss';

import { useJewelryBanners } from 'api/Banners/useJewelryBanners';
import { useFetchOurItems } from 'api/Catalog/useFetchOurItems';

import { ItemBrand } from 'widgets/ItemBrand/ui/ItemBrand';
import { MainSlider } from 'widgets/Sliders/ui/MainSlider';
import { MapSection } from 'widgets/MapSection';
import SmartWidgets from 'entities/Revievs/SmartWidgets/SmartWidgets';
import { JWorksTeam } from 'widgets/JWorksTeam';
import { WorkTypes } from 'widgets/WorkTypes';
import { CarouselItems } from 'widgets/CarouselItems';
// import Revievs from 'modules/Revievs/Revievs'

function JWorksPage() {
  const { ourItems, ourItemsLoading, fetchOurItems } = useFetchOurItems();
  const { jewelryBannersLoading, jewelryBanners, getJewelryBanners } =
    useJewelryBanners();

  useEffect(() => {
    getJewelryBanners();
    fetchOurItems();
  }, []);

  return (
    <div className={styles.jewelryPage}>
      <MainSlider banners={jewelryBanners} loading={jewelryBannersLoading} />
      <CarouselItems
        items={ourItems}
        title="Наши работы"
        loading={ourItemsLoading}
        link={`/catalog?page=1&sort=-id&type=Наше`}
      />
      <WorkTypes />
      <ItemBrand />
      <MapSection mapType="jews" />
      <JWorksTeam />
      <SmartWidgets title={true} />
      {/* Это наш стандартный модуль с отзывами */}
      {/* <Revievs /> */}
    </div>
  );
}

export { JWorksPage };
