import React, { useState, useEffect } from 'react';
import styles from './Receiv.module.scss';

import { useOrder } from 'store/order';
import {
  defaultRecievMethods,
  manelyakRecievMethods,
} from '../model/recievMethods';
import { RecievItem } from 'widgets/Cart/ui/Receiv/components/RecievItem';

function Receiv({ isManelyak }) {
  const recievMethods = isManelyak
    ? manelyakRecievMethods
    : defaultRecievMethods;

  const { delivery_type, setDeliveryType, resetDeliveryTypePartly } = useOrder(
    (state) => ({
      delivery_type: state.delivery_type,
      setDeliveryType: state.setDeliveryType,
      resetDeliveryTypePartly: state.resetDeliveryTypePartly,
    }),
  );

  const [currentMethod, setCurrentMethod] = useState(null);

  // Выбираем метод
  const onClickMethod = (key) => {
    const selectedMethod = recievMethods.find((method) => method.key === key);

    if (selectedMethod && (!currentMethod || key !== currentMethod.key)) {
      setCurrentMethod(selectedMethod);
      setDeliveryType(selectedMethod.key);
      // Сбрасываем значения при изменении методов на дефолтные
      resetDeliveryTypePartly();
    }
  };

  useEffect(() => {
    if (isManelyak) {
      setDeliveryType(manelyakRecievMethods[0].key);
      setCurrentMethod(manelyakRecievMethods[0]);
    }
    // Подтягиваем значение из стора, если оно было ранне установлено
    if (delivery_type) {
      setCurrentMethod(
        recievMethods.find((method) => method.key === delivery_type),
      );
    }
  }, [isManelyak, currentMethod]);

  return (
    <div className={styles.reciev}>
      <h2 className={styles.title}>Способ получения заказа</h2>
      <div className={styles.recievMethods}>
        {recievMethods.map((method) => (
          <RecievItem
            key={method.key}
            method={method}
            currentMethod={currentMethod}
            onClickMethod={() => onClickMethod(method.key)}
          >
            {method.children}
          </RecievItem>
        ))}
      </div>
    </div>
  );
}

export { Receiv };
