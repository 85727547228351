import React from 'react';
import styles from './Footer.module.scss';

import { Logo } from 'shared/ui/Logo';
import { FooterNavbar } from 'widgets/Footer/ui/FooterNavbar';
import { CompanyInfo } from 'widgets/Footer/ui/CompanyInfo';
import { SocialMedia } from 'shared/ui/SocialMedia';

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.navbarSection}>
          <Logo />
          <FooterNavbar />
        </div>
        <div className={styles.contactsSection}>
          <div className={styles.socialMedia}>
            <SocialMedia />
          </div>
          <CompanyInfo />
        </div>
      </div>
    </footer>
  );
}

export { Footer };
