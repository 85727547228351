import React from 'react';
import styles from './CompanyInfo.module.scss';

function CompanyInfo() {
  return (
    <p className={styles.companyInfo}>
      ИП Манеляк Людмила Александровна
      <br />
      ИНН: 525629285613
      <br />
      ИП Миронов Дмитрий Владимирович
      <br />
      ИНН: 525629844212
      <br />
      ИП Миронов Игорь Владимирович
      <br />
      ИНН: 525632873816
      <br />
      ООО «Ломбард «Золотой Гранд»
      <br />
      ИНН: 5260480502
    </p>
  );
}

export { CompanyInfo };
