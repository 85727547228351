import React, { useRef } from 'react';
import styles from '../Forms.module.scss';
import { useForm } from 'react-hook-form';

import { useGetRegCode } from 'api/Auth/Register/useGetRegCode';
import validatePhone from 'shared/lib/validatePhone';
import {
  onPhoneInput,
  onPhoneKeyDown,
  onPhonePaste,
} from 'shared/lib/validatePhoneNumber';

import ReCAPTCHA from 'react-google-recaptcha';
import { PrivacyPolicy } from 'shared/ui/PrivacyPolicy';
import { MainButton } from 'shared/ui/MainButton';
import { LinkButton } from 'shared/ui/LinkButton';

export default function RegCodeForm() {
  const { regCodeError, regCodeLoading, getRegCode } = useGetRegCode();
  const recaptchaRef = useRef();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = async (data) => {
    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      await getRegCode(data, 'call', '/sign-up');
    }
  };

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputsContainer}>
          <div className={styles.inputContainer}>
            <input
              className={styles.input}
              type="text"
              {...register('first_name', {
                required: 'Обязательно к заполнению',
                minLength: {
                  value: 2,
                  message: 'Не должно быть меньше двух символов',
                },
                maxLength: {
                  value: 20,
                  message: 'Введите свое имя',
                },
              })}
              placeholder="Введите имя"
            />
            <div className={styles.error}>
              {errors?.first_name && (
                <p>{errors?.first_name?.message || 'Ошибка'}</p>
              )}
            </div>
          </div>
          <div className={styles.inputContainer}>
            <input
              className={styles.input}
              type="text"
              {...register('last_name', {
                required: 'Обязательно к заполнению',
                minLength: {
                  value: 2,
                  message: 'Не должно быть меньше двух символов',
                },
                maxLength: {
                  value: 20,
                  message: 'Введите свою фамилию',
                },
              })}
              placeholder="Введите фамилию"
            />

            <div className={styles.error}>
              {errors?.last_name && (
                <p>{errors?.last_name?.message || 'Ошибка'}</p>
              )}
            </div>
          </div>
          <div className={styles.inputContainer}>
            <input
              className={styles.input}
              type="tel"
              onPaste={onPhonePaste}
              onInput={onPhoneInput}
              onKeyDown={onPhoneKeyDown}
              {...register('phone_number', {
                required: 'Обязательно к заполнению',
                validate: (value) => validatePhone(value.trim()),
              })}
              placeholder="+7 (900) 123 45 67"
            />
            {regCodeError !== null ? (
              <div className={styles.error}>
                <p>{regCodeError}</p>
              </div>
            ) : (
              <div className={styles.error}>
                {errors?.phone_number && (
                  <p>
                    {errors?.phone_number?.message || 'Кажется, здесь ошибка'}
                  </p>
                )}
              </div>
            )}
          </div>
          <MainButton
            type="submit"
            text="Создать аккаунт"
            loading={regCodeLoading}
          />
        </div>
        <div className={styles.tip}>
          <LinkButton text="У меня уже есть аккаунт. Войти" link="/login" />
        </div>
        <div className={styles.alignCenter}>
          <PrivacyPolicy />
        </div>
      </form>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_CAPTHA_KEY}
        size="invisible"
      />
    </>
  );
}
