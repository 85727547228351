import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Search.module.scss';
import qs from 'qs';

import { useSearch } from 'store/search';
import { useCatalogPagination } from 'store/pagination';

import { BsFillSearchHeartFill } from 'react-icons/bs';
import { GoXCircleFill } from 'react-icons/go';
import { useFilters } from 'store/filters';
import { useOutsideClick } from 'shared/lib/useOutsideClick';
import { useWindowSize } from 'shared/lib/windowSizeChecker';

function Search() {
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const { pathname } = useLocation();
  const URL = window.location.search;
  const params = qs.parse(URL.substring(1));

  const [showSearchInput, setShowSearchInput] = useState(false);
  const [value, setValue] = useState('');
  const [mobile, setMobile] = useState(false);
  const windowSize = useWindowSize();

  const setSearch = useSearch((state) => state.setSearch);
  const setPagination = useCatalogPagination((state) => state.setPagination);
  const resetFilters = useFilters((state) => state.resetFilters);

  // Выполняем поиск
  const onFormSubmit = (event) => {
    event.preventDefault();
    setSearch(value);
    resetFilters();

    if (value !== '') {
      setPagination(1);
      // Переходим в каталог, если мы еще не там
      if (pathname !== '/catalog/') {
        navigate('/catalog/');
      }
    }
  };

  const onResetSearch = () => {
    setSearch(null);
    setValue('');
  };

  // Закрываю при нажатии вне блока
  useOutsideClick(searchRef, () => setShowSearchInput(false));

  useEffect(() => {
    if (windowSize <= 768) {
      setMobile(true);
    } else setMobile(false);
  }, [windowSize]);

  // Проверяем URL, на наличие параметра
  useEffect(() => {
    if (params.search !== undefined && params.search !== null)
      setValue(params.search);
  }, []);

  if (mobile) {
    return (
      <form className={styles.searchForm} onSubmit={onFormSubmit}>
        <BsFillSearchHeartFill className={styles.icon} onClick={onFormSubmit} />
        <input
          className={styles.searchInput}
          type="text"
          placeholder="Найти изделие"
          onChange={(event) => setValue(event.target.value.trim())}
          value={value}
        />
        {value !== '' ? (
          <GoXCircleFill
            className={styles.closeButton}
            onClick={onResetSearch}
            size={16}
          />
        ) : null}
      </form>
    );
  }

  return (
    <div className={styles.searchWrapper}>
      <BsFillSearchHeartFill
        className={styles.mainIcon}
        onClick={() => setShowSearchInput(true)}
        size={22}
      />
      {showSearchInput && (
        <div className={styles.formWrapper}>
          <form
            className={styles.searchForm}
            onSubmit={onFormSubmit}
            ref={searchRef}
          >
            <BsFillSearchHeartFill
              className={styles.icon}
              onClick={onFormSubmit}
            />
            <input
              className={styles.searchInput}
              type="text"
              placeholder="Найти изделие"
              onChange={(event) => setValue(event.target.value.trim())}
              value={value}
            />
            {value !== '' ? (
              <GoXCircleFill
                className={styles.closeButton}
                onClick={onResetSearch}
                size={16}
              />
            ) : null}
          </form>
        </div>
      )}
    </div>
  );
}

export { Search };
