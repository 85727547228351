import React from 'react';
import styles from './ManelyakMessage.module.scss';
import { NotificationModal } from 'entities/Modal/ui/components/ModalLayouts/NotificationModal';
import { MainButton } from '../MainButton';

export default function ManelyakMessage({ active, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <NotificationModal active={active}>
      <div className={styles.container}>
        <p className={styles.message}>
          В вашей корзине есть товары с отметкой{' '}
          <span className={styles.isManelyakMessage}>
            Получи&nbsp;дополнительную&nbsp;скидку...
          </span>{' '}
          . В&nbsp;таком случае заказ можно оформить только <b>самовывозом</b> и
          только при <b>оплате наличными</b> на филиале
        </p>
        <MainButton text="Понятно" onClick={handleClose} />
      </div>
    </NotificationModal>
  );
}
