import React from 'react';
import styles from './Navbar.module.scss';
import { NavLink } from 'react-router-dom';

function Navbar() {
  return (
    <nav className={styles.navbar}>
      <NavLink
        to="/"
        className={({ isActive }) =>
          isActive ? styles.linkButtonActive : styles.linkButton
        }
      >
        Главная
      </NavLink>
      <NavLink
        to="catalog"
        className={({ isActive }) =>
          isActive ? styles.linkButtonActive : styles.linkButton
        }
      >
        Каталог
      </NavLink>
      <NavLink
        to="yuvelirnyie-rabotyi"
        className={({ isActive }) =>
          isActive ? styles.linkButtonActive : styles.linkButton
        }
      >
        Ювелирные работы
      </NavLink>
      <NavLink
        to="about"
        className={({ isActive }) =>
          isActive ? styles.linkButtonActive : styles.linkButton
        }
      >
        Контакты
      </NavLink>
      <NavLink
        to="https://lombard.grand.gold/"
        className={({ isActive }) =>
          isActive ? styles.linkButtonActive : styles.linkButton
        }
      >
        Ломбард
      </NavLink>
      <NavLink
        to="/vacancy"
        className={({ isActive }) =>
          isActive ? styles.linkButtonActive : styles.linkButton
        }
      >
        Вакансии
      </NavLink>
    </nav>
  );
}

export { Navbar };
